<template>
  <v-app class="course-layout">
    <v-navigation-drawer v-model="drawer" app color="accent" dark>
      <v-img :src="course.img" width="100%" />
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="white">
            <v-icon color="primary">person</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item>
          <v-list-item-title class="primary--text font-weight-bold">{{
            course.name.toLocaleUpperCase("tr-TR")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          @click.prevent="goTo(item)"
          :to="item.link"
          :exact="item.exact"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item link :to="{ name: 'learner-home' }">
          <v-list-item-action>
            <v-icon>first_page</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Eğitimlerime Dön</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click.prevent="signOut()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Çıkış</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevate-on-scroll>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">{{ title }}</span>
      </v-toolbar-title>
      <v-spacer />
      <div class="col-3 .col-md-4 pa-0 d-flex align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-progress-linear
              :value="courseRegistration.progress"
              color="primary"
              height="8"
              background-color="grey lighten-2"
              class="rounded-xl"
              v-on="on"
              v-bind="attrs"
            />
          </template>
          <span>%{{ progress }} tamamlandı</span>
        </v-tooltip>

        <v-btn icon color="primary" x-large class="ml-3" @click="getCert()">
          <v-icon>mdi-certificate</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main class="content">
      <router-view :course="course" :courseRegistration="courseRegistration" />
    </v-main>

    <v-dialog
      v-model="dialogCert"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card color="black" flat rounded="0">
        <v-card-title class="primary white--text pr-3">
          Katılım Sertifikasınız Hazır!
          <v-spacer />
          <v-btn color="white" icon @click="dialogCert = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-container class="fill-height pa-0 ma-0">
          <v-row no-gutters>
            <v-col>
              <div class="cert">
                <iframe :src="certUrl" frameborder="0"></iframe>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  data: () => ({
    drawer: null,
    title: "",
    currentMenuItem: {},
    menuItems: [
      {
        icon: "home",
        title: "Giriş",
        link: { name: "course-home" },
        exact: true
      },
      {
        icon: "play_circle_filled",
        title: "Konular",
        link: { name: "course-lessons" },
        exact: false
      },
      {
        icon: "chat",
        title: "Tartışmalar",
        link: { name: "course-discussions" },
        exact: false
      }
    ],
    course: {
      id: "xxx",
      name: "",
      code: "",
      img: "",
      urlCode: "",
      learningOutcomes: [],
      modules: [],
      desc: {
        short: "",
        long: ""
      },
      ebook: ""
    },
    courseRegistration: {
      id: "",
      uid: "",
      courseId: "",
      dateCreated: null,
      isActive: true,
      progress: 0,
      completedLessons: [],
      progressEbook: 0
    },
    dialogCert: false,
    certUrl: ""
  }),
  computed: {
    userSignedIn() {
      return this.$store.getters["auth/userSignedIn"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    progress() {
      return Math.round(this.courseRegistration.progress);
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOut");
      this.$router.push({ name: "home" }).catch(() => {
        return false;
      });
    },
    goTo(item) {
      this.currentMenuItem = item;
      this.title = item.title;
      this.$router.push(item.link).catch(() => {
        return false;
      });
    },
    async getCourseRegistration() {
      const regQs = await firebase
        .firestore()
        .collection("courseRegistrations")
        .where("uid", "==", this.user.id)
        .where("courseId", "==", this.course.id)
        .get();

      if (!regQs.empty) {
        const reg = regQs.docs[0];

        this.courseRegistration.id = reg.id;
        this.courseRegistration.uid = reg.data().uid;
        this.courseRegistration.courseId = reg.data().courseId;
        this.courseRegistration.isActive = reg.data().isActive;
        this.courseRegistration.progress = reg.data().progress;
        this.courseRegistration.dateCreated = reg.data().dateCreated.toDate();
        if (reg.data().completedLessons) {
          this.courseRegistration.completedLessons = reg.data().completedLessons;
        }
        if (reg.data().progressEbook) {
          this.courseRegistration.progressEbook = reg.data().progressEbook;
        }
      }
    },
    async getCourse() {
      try {
        // Ders bilgilerini al
        const courseQs = await firebase
          .firestore()
          .collection("courses")
          .where("urlCode", "==", this.$route.params.courseUrlCode)
          .limit(1)
          .get();

        if (!courseQs.empty) {
          const course = courseQs.docs[0];

          this.course.id = course.id;
          this.course.name = course.data().name;
          this.course.code = course.data().code;
          this.course.urlCode = course.data().urlCode;
          this.course.img = `/courses/${this.course.urlCode}/preview-image.jpg`;
          this.course.learningOutcomes = course.data().learningOutcomes;
          this.course.modules = course.data().modules;
          this.course.desc = course.data().desc;
          this.course.ebook = course.data().ebook;

          // Kullanıcının ders kayıt bilgilerini al
          await this.getCourseRegistration();
        }
      } catch (err) {
        this.$notify(
          `Ders bilgileri alınamadı. Lütfen daha sonra tekrar deneyiniz.`
        );
      }
    },
    async getCertUrl() {
      return await firebase
        .storage()
        .ref(`certificates/${this.courseRegistration.id}.pdf`)
        .getDownloadURL();
    },
    async getCert() {
      // Dersi tamamlamamışsa kullanıcıyı bilgilendir
      if (this.courseRegistration.progress < 100) {
        this.$notify({
          title: "Eğitimi tamamlamadınız!",
          text: `Katılım sertifikanızı alabilmek için tüm
          modülleri tamamlamanız gerekmektedir.`
        });
        return;
      }

      // Sertifika adresi alınmış mı kontrol et
      if (this.certUrl) {
        this.dialogCert = true;
        return;
      }

      try {
        this.certUrl = await this.getCertUrl();
        this.dialogCert = true;
      } catch {
        this.$notify({
          title: "Lütfen bekleyin!",
          text: "Sertifikanız oluşturuluyor...",
          duration: -1
        });

        // Sertifikayı oluştur
        const createCertificate = firebase
          .functions()
          .httpsCallable("certificate-createCertificate");

        await createCertificate({
          cregId: this.courseRegistration.id
        });
      }

      // Her 1 sn'de sertfikayı kontrol et.
      const timer = setInterval(async () => {
        try {
          this.certUrl = await this.getCertUrl();

          if (this.certUrl) {
            this.$notify({ clean: true });
            this.dialogCert = true;

            clearInterval(timer);
          }
        } catch {
          console.error("Sertifika bulunamadı");
        }
      }, 1000);
    }
  },
  async mounted() {
    this.currentMenuItem = this.menuItems[0];
    this.title = this.currentMenuItem.title;

    await this.getCourse();
  }
};
</script>

<style lang="scss" scoped>
.course-layout {
  .content {
    // background-image: url("../../assets/img/bg-top.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }

  .course {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: all ease 0.3s;

    &:hover {
      transform: scale(1.05);
    }

    .v-card__title {
      min-height: 50px;
      align-items: flex-start;
    }

    .v-card__subtitle {
      span {
        display: inline-block;
        width: 25px;
        height: 5px;
        border-radius: 2px;
        margin-left: 10px;
      }
    }
  }

  .cert {
    height: 100%;

    iframe {
      position: absolute;
      top: 64px;
      left: 0;
      width: 100%;
      height: calc(100% - 64px);
      border: none 0 transparent;
    }
  }
}
</style>
